<template>
  <div class="view pa24" style="position: relative">
    <div class="d-flex">
      <div>
        <!-- <el-button class="mr10" type="primary">添加分类</el-button> -->
        <el-input clearable class="w221 mr10" v-model="memberName" placeholder="请输入会员名称"/>
        <el-input clearable class="w221 mr10" v-model="companyName" placeholder="请输入企业名称"/>
        <el-input clearable class="w221 mr10" v-model="headPhone" placeholder="请输负责人电话"/>
        <el-select
            clearable
            v-model="auditStatus"
            class="w221 mb10 mr10"
            placeholder="选择绑定状态"
        >
          <el-option
              v-for="(item,index) in auditList"
              :key="'info3'+ index"
              :label="item.name"
              :value="item.index"
          >
          </el-option>
        </el-select>
        <el-select
            clearable
            v-model="isOpen"
            class="w221 mb10 mr10"
            placeholder="是否开通名片"
        >
          <el-option
              v-for="(item,index) in isOpenList"
              :key="'info2'+ index"
              :label="item.name"
              :value="item.index"
          >
          </el-option>
        </el-select>
        <el-button type="primary" @click="fnQueryClick">查询</el-button>
      </div>
      <div style="margin-left: auto">
        <!--        <el-button class="ml10" type="primary" @click="ceshi"-->
        <!--        >测试会员-->
        <!--        </el-button-->
        <!--        >-->
        <el-button class="ml10" type="primary" @click="centerDialogVisible = true"
        >添加会员
        </el-button
        >
        <el-button class="ml10" type="primary" @click="addMembersFc"
        >批量设置会员
        </el-button
        >
        <el-button class="ml10" type="danger" @click="delPl" v-if="multipleSelection.length>0"
        >批量删除
        </el-button
        >
        <!--        <el-button class="ml10" type="primary" @click="centerDialogVisible = true"-->
        <!--        >导入数据-->
        <!--        </el-button-->
        <!--        >-->
      </div>
    </div>
    <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSelectionChange="handleSelectionChange"
        :currentPage="currentPage"
        :total="total"
        style="width: 100%;position: relative"
    >
      <template v-slot:table>
        <el-table-column type="selection"/>
        <el-table-column type="index" align="center" label="编号"/>
        <el-table-column prop="userName" align="center" label="会员姓名" width="200px"/>
        <el-table-column prop="shareNum" align="center" label="会员照片">
          <template slot-scope="scope">
            <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.img"
                :preview-src-list="srcList">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="gender" align="center" label="性别"/>
        <el-table-column prop="tel" align="center" label="联系电话" width="130px"/>
        <el-table-column prop="level" align="center" label="入会级别" width="100px"/>
        <el-table-column prop="nickname" align="center" label="微信昵称" width="200px"/>
        <el-table-column align="center" label="微信绑定">
          <template slot-scope="scope">
            <div v-if="scope.row.ifBind==0" style="cursor: pointer">已绑定</div>
            <div v-if="scope.row.ifBind==1" style="color: #20aee3;cursor: pointer" @click="changewxImg(scope.row)">未绑定
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="微信头像">
          <template slot-scope="scope">
            <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.wxImg"
                :preview-src-list="srcList1">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="idNumber" align="center" label="身份证号" width="200px"/>
        <el-table-column prop="socialPosition" align="center" label="社会职务"/>
        <el-table-column prop="creditCode" align="center" label="社会信用代码" width="200px"/>
        <el-table-column prop="companyName" align="center" label="企业名称" width="200px"/>
        <el-table-column prop="workersCount" align="center" label="职工人数"/>
        <el-table-column prop="industry" align="center" label="所属行业"/>
        <el-table-column prop="payDate" align="center" label="会费缴纳时间" width="200px"/>
        <el-table-column prop="endDate" align="center" label="到期时间" width="300px"/>
        <el-table-column align="center" label="企业端版本">
          <template slot-scope="scope">
            <div>{{ scope.row.companyType == 2 ? "高级版" : scope.row.companyType == 1 ? "普通版" : scope.row.companyType == 3 ? "商会版" : scope.row.companyType == 4?"园区版":"" }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" align="center" label="创建时间" width="300px"/>
        <el-table-column align="center" label="名片开关">
          <template slot-scope="scope">
            <el-switch
                @change="saveStatus(scope.row)"
                v-model="scope.row.cardSwitch"
                active-color="#51CDCB"
                inactive-color="#dadde5">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="300px" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="toBasic(scope.row)">基本信息</el-button>
            <el-button class="" type="text" @click="toCV(scope.row)">履历信息</el-button>
            <!--            <el-button class="" type="text" @click="toDevelopment(scope.row)">发展信息</el-button>-->
            <el-button class="mr10" type="text" @click="toHome(scope.row)">企业主页</el-button>
            <customPopconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm='del(scope.row)'
                icon="el-icon-info"
                icon-color="red"
                title="确定要删除吗？">
              <el-button slot="reference" style="color: #fd634e" type="text">删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <div class="code_img" v-if="twoCode">
      <img src="../../../static/close.png" alt="" @click="close"
           style="width: 30px;height: 30px;position: absolute;right:0;top: 0;cursor: pointer">
      <img :src="twoCode" alt="" style="width: 430px;height: 430px">
    </div>
    <el-dialog
        title="添加会员"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        :destroy-on-close="true"
        width="50%"
        center
    >
      <postArticles @submitForm="submitForm" ref="postArticles"/>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="请选择类型"
        :visible.sync="typeDialogVisible"
        :modal-append-to-body="false"
        :destroy-on-close="true"
        width="50%"
        center
    >
      <div class="type_wrap">
        <span :class="navIndex==1?'classA':'classB'" @click="navType(1)">会长风采</span>
        <span :class="navIndex==2?'classA':'classB'" @click="navType(2)">优秀会员</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="typeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import postArticles from "../components/postArticles";
import customPopconfirm from "@/components/common/customPopconfirm";
import {
  addMember,
  selectMemberPageList,
  delMember,
  upMember,
  selectWxCodeByMemberId,
  createExcellent,
  bathDeleteMembers,
  addTestMember,
  pushExcellentMember
} from "@/api/members";
import {selectMemberFeeList} from '@/api/organization.js'

let base64 = require('js-base64').Base64
export default {
  name: "membersList",
  data() {
    return {
      navIndex:1,
      typeDialogVisible:false,
      memberName: '',
      companyName: '',
      headPhone: '',
      versionType: '',
      isOpen: '',
      versionList: [{index: 0, name: '高级'}, {index: 1, name: '普通'}],
      isOpenList: [{index: 0, name: '是'}, {index: 1, name: '否'}],
      auditList: [{index: 0, name: '已绑定'}, {index: 3, name: '未绑定'}],
      auditStatus:'',
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      keyWord: "", //文章标题
      pickerValue: "", //选择时间
      centerDialogVisible: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      tableData: [],
      industryList: [],
      twoCode: '',
      memberId: '',
      srcList: [],
      srcList1: [],
      multipleSelection: [],
      // customerIds: '',
    };
  },
  created() {
    this.queryPageMoney()
  },
  components: {
    commonTable,
    postArticles,
    customPopconfirm
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // this.customerIds = this.multipleSelection.map(item => item.customerId);
    },
    //二维码显示
    async changewxImg(row) {
      this.memberId = row.memberId
      let ajax = {
        companyId: localStorage.getItem("companyId"),
        memberId: this.memberId
      }
      try {
        const result = await selectWxCodeByMemberId(ajax);
        this.twoCode = result.data.twoCode
      } catch (error) {

      }
    },
    async close(item) {
      this.twoCode = ''
    },
    async queryPageMoney() {
      let data = {
        associationId: localStorage.getItem("associationId"),
      };
      try {
        const result = await selectMemberFeeList(data);
        this.industryList = result.data;
        this.queryPage();
      } catch (error) {

      }
    },
    /**@method 获取*/
    async queryPage(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        examineStatusList: '0,3',
        userStatus: 1,
        ...payload
      };
      try {
        this.loading = true;
        const result = await selectMemberPageList(data);
        this.loading = false;
        const {total, list} = result.data.pageInfo;
        console.log(list)
        let a = []
        let b = []
        for (let i in list) {
          if (list[i].img) {
            a.push(list[i].img)
          }
          if (list[i].wxImg) {
            b.push(list[i].wxImg)
          }
          if (list[i].nickname) {
            list[i].nickname = base64.decode(list[i].nickname);
          }
          list[i].createTime = this.renderTime(list[i].createTime)
          if (list[i].endDate) {
            list[i].endDate = this.renderTime(list[i].endDate)
          }
          if (list[i].payDate) {
            list[i].payDate = this.renderTime(list[i].payDate)
          }
          list[i].version = list[i].version == 1 ? '高级' : '普通'
          list[i].gender = list[i].gender == 1 ? '男' : '女'
          if (list[i].cardSwitch == 0) {
            list[i].cardSwitch = true
          } else {
            list[i].cardSwitch = false
          }
          // if (list[i].ifBind == 0) {
          //   list[i].ifBind = '已绑定'
          // } else {
          //   list[i].ifBind = '未绑定'
          // }
          for (let j in this.industryList) {
            if (list[i].level == this.industryList[j].memberLevelId) {
              list[i].level = this.industryList[j].levelName
            }
          }
        }
        this.srcList = a
        this.srcList1 = b
        this.tableData = list;
        console.log(this.tableData)
        this.total = total;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    handleSizeChange(val) {
      this.pageSize = val;
      let data = {
        userName: this.memberName,
        companyName: this.companyName,
        tel: this.headPhone,
        // version: this.versionType,
        cardSwitch: this.isOpen,
        examineStatus:this.auditStatus
      };
      this.queryPage(data);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      let data = {
        userName: this.memberName,
        companyName: this.companyName,
        tel: this.headPhone,
        // version: this.versionType,
        cardSwitch: this.isOpen,
        examineStatus:this.auditStatus
      };
      this.queryPage(data);
    },
    /**@method 查询 */
    fnQueryClick() {
      this.currentPage = 1;
      let data = {
        userName: this.memberName,
        companyName: this.companyName,
        tel: this.headPhone,
        // version: this.versionType,
        cardSwitch: this.isOpen,
        examineStatus:this.auditStatus
      };
      this.queryPage(data);
    },
    determine(data) {
      this.$refs.postArticles.submitForm(data);
    },
    /**
     * @method 发布文章提交
     * @param {Object} val - 表单值
     */
    async submitForm(val) {
      let data = {
        associationId: localStorage.getItem("associationId"),
        companyName: val.enterpriseName,
        userName: val.name,
        gender: val.sex,
        level: val.industry,
        tel: val.phone,
        idNumber: val.idCard,
        creditCode: val.creditCode,
        assistantName: val.assistantName,
        assistantTel: val.assistantPhone,
        examineStatus: 0,
        ifBind: 1,
        ifLaunch: 1,
        status: 1,
        ifResume: 1,
        ifSelf: 1,
        cardSwitch: 0,
        userStatus: 1,
        ifBoss: val.membershiptypeId, //入会类型
      };
      try {
        await addMember(data, {showLoading: true});
        this.centerDialogVisible = false;
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      if (val.ifBind == 0) {
        try {
          await upMember({memberId: val.memberId,userStatus:0});
          await this.queryPage();
        } catch (error) {
          console.log(error)
        }
      }else if (val.ifBind == 1) {
        try {
          await delMember({memberId: val.memberId});
          await this.queryPage();
        } catch (error) {
          console.log(error)
        }
      }
    },
    toBasic(row) {
      this.$router.push({
        path: "./basicInformation",
        query: {
          memberId: row.memberId,
        },
      });
    },
    toCV(row) {
      if (row.ifBind == 1) {
        this.$message({
          type: 'error',
          message: '该会员未绑定，请先绑定微信!'
        });
      }else {
        console.log(row.userId)
        this.$router.push({
          path: "./CVList",
          query: {
            memberId: row.memberId,
            userId:row.userId
          },
        });
      }
    },
    toHome(row) {
      this.$router.push({
        path: "./companyCardPage",
        query: {
          companyId: row.companyId,
        },
      });
    },
    async saveStatus(row) {
      let ajax = {
        cardSwitch: row.cardSwitch ? 0 : 1,
        memberId: row.memberId
      }
      if (row) {
        const result = await upMember(ajax);
        this.$message({
          type: "success",
          message: "保存成功",
        });
        let data = {
          userName: this.memberName,
          companyName: this.companyName,
          tel: this.headPhone,
          version: this.versionType,
          cardSwitch: this.isOpen,
          examineStatus:this.auditStatus
        };
        await this.queryPage(data);
      }
    },
    addMembersFc() {
      this.typeDialogVisible = true
    },
    addMembersFc1() {

    },
    delPl() {
      this.$confirm('批量删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let a = []
        for (let i in this.multipleSelection) {
          a.push({memberId: this.multipleSelection[i].memberId, ifBind: this.multipleSelection[i].ifBind})
        }
        let ajax = {
          details: JSON.stringify(a),
          associationId: localStorage.getItem("associationId"),
        }
        const result = bathDeleteMembers(ajax);
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
        this.queryPage();
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    ceshi(){
      let ajax = {
        associationId: localStorage.getItem("associationId"),
      }
      const result = addTestMember(ajax);
    },
    navType(num){
      this.navIndex = num
    },
    toSubmit(){
      if (this.navIndex == 1) {
        if (this.multipleSelection.length == 0) {
          this.$message({
            type: 'error',
            message: '请选择会员!'
          });
        }else{
          this.$confirm('批量设置会长, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            for (let i in this.multipleSelection) {
              this.multipleSelection[i].status = 0
              this.multipleSelection[i].version = this.multipleSelection[i].version == '高级' ? 0 : 1
              this.multipleSelection[i].gender = this.multipleSelection[i].gender == '男' ? 1 : 2
              this.multipleSelection[i].cardSwitch = this.multipleSelection[i].cardSwitch ? 0 : 1
              for (let j in this.industryList) {
                if (this.multipleSelection[i].level == this.industryList[j].levelName) {
                  this.multipleSelection[i].level = this.industryList[j].memberLevelId
                }
              }
            }
            let ajax = {
              details: JSON.stringify(this.multipleSelection),
              associationId: localStorage.getItem("associationId"),
            }
            const result = createExcellent(ajax);
            this.$message({
              type: 'success',
              message: '设置成功!'
            });
            this.typeDialogVisible = false
            this.queryPage();
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            });
          });
        }
      }else if (this.navIndex == 2) {
        if (this.multipleSelection.length == 0) {
          this.$message({
            type: 'error',
            message: '请选择会员!'
          });
        }else{
          this.$confirm('批量设置优秀会员, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            // for (let i in this.multipleSelection) {
            //   this.multipleSelection[i].status = 1
            //   this.multipleSelection[i].version = this.multipleSelection[i].version == '高级' ? 0 : 1
            //   this.multipleSelection[i].gender = this.multipleSelection[i].gender == '男' ? 1 : 2
            //   this.multipleSelection[i].cardSwitch = this.multipleSelection[i].cardSwitch ? 0 : 1
            //   for (let j in this.industryList) {
            //     if (this.multipleSelection[i].level == this.industryList[j].levelName) {
            //       this.multipleSelection[i].level = this.industryList[j].memberLevelId
            //     }
            //   }
            // }
            let id = this.multipleSelection.map((item)=>{
              return item.memberId
            })
            let ajax = {
              memberIdList: id.join(','),
              associationId: localStorage.getItem("associationId"),
            }
            pushExcellentMember(ajax)
                .then(res => {
                  console.log(res)
                  if (res.code == 200) {
                    this.$message({
                      type: 'success',
                      message: "保存成功",
                    })
                    this.typeDialogVisible = false
                    this.queryPage();
                  }else {
                    this.$message({
                      type: 'error',
                      message: res.message,
                    })
                    this.typeDialogVisible = false
                  }
                })

          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            });
          });
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.code_img {
  position: absolute;
  top: 30%;
  z-index: 10000;
  left: 35%;
  background: #FFFFFF;
}

/deep/ .el-table__body {
  position: relative;
}
.type_wrap{
  display: flex;
  justify-content: center;
}
.type_wrap>span{
  margin-right: 30px;
  cursor: pointer;
}
.classB{
  padding: 8px 30px;
  border: 1px solid #e9eaec;
  background: #fff;
  color: #666;
  border-radius: 5px;
}
.classA{
  border-radius: 5px;
  padding: 8px 30px;
  color: #FFF;
  background-color: #51CDCB;
}
</style>
