import httpUtil from "@/utils/httpUtil";

/**添加账号 */
export const addExperienceAcount =  params => httpUtil.post("/api/assPc/experienceAccount/addExperienceAcount", params);

/**查询体验账号*/
export const queryListPage =  params => httpUtil.post("/api/assPc/experienceAccount/queryListPage", params);

/**删除体验账号*/
export const deleteExperienceAcount =  params => httpUtil.post("/api/assPc/experienceAccount/deleteExperienceAcount", params);

/**  延长体验时间 */
export const uodateUserRecord =  params => httpUtil.post("/api/assPc/experienceAccount/uodateUserRecord", params);

/** 获取太阳码*/
export const getPageSunCodeInfoByAss =  params => httpUtil.post("/api/assPc/PageSunCode/getPageSunCodeInfoByAss", params);

/**体验记录*/
export const queryListRecordPage =  params => httpUtil.post("/api/assPc/experienceAccount/queryListRecordPage", params);

