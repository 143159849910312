<template>
  <div class="view pa24" style="position: relative;height: 100%">
    <div style="margin-left: auto">
      <el-button class="ma" type="primary" style="margin-right: 10px!important;" @click="centerDialogVisible = true;"
      >+ 添加账号</el-button
      >
      <el-button class="ma"  @click="experienceRecord"
      >体验记录</el-button
      >
    </div>
    <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
        style="width: 100%;position: relative"
    >
      <template v-slot:table>
        <el-table-column prop="accountName" align="center" label="账号名称"/>
        <el-table-column prop="wxUrl" align="center" label="微信头像" >
          <template slot-scope="scope">
            <el-image
                style="width: 80px; height: 40px"
                :src="scope.row.wxUrl"
                :preview-src-list="srcList">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="nickeName" align="center" label="昵称"/>
        <el-table-column prop="createTime" align="center" label="创建时间"/>
        <el-table-column prop="reamark" align="center" label="备注"/>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" v-if="scope.row.userId" plain @click="extendedTime(scope.row)"
            >延长体验时间</el-button
            >
            <el-button v-else plain @click="bindUser(scope.row)"
            >绑定体验者</el-button
            >
            <customPopconfirm
                class="ma ml10 mb10"
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="del(scope.row.experienceId)"
                title="确认删除？">
              <el-button type="danger" slot="reference" plain>删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <div>
      <span style="color: red;">*</span>注：每个体验者默认体验三天，且三天内最多创建五个体验者账号
    </div>
    <el-dialog
        title="添加账号"
        :visible.sync="centerDialogVisible"
        @close="close"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <el-form
          ref="ruleForm" :model="ruleForm" :rules="rules" label-width="auto" class="view pa24" label-position="left"
      >
        <el-form-item prop="title" label="账号名称">
          <el-input
              clearable
              v-model="ruleForm.title"
              style="width: 100%"
              placeholder="请输入账号名称"
              maxlength="50"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="desc" label="备注">
          <el-input
              :rows="3"
              type="textarea"
              clearable
              v-model="ruleForm.desc"
              style="width: 100%"
              placeholder="请输入备注"
              maxlength="500"
              show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="选择时间"
        :visible.sync="centerDialogVisible1"
        :modal-append-to-body="false"
        width="30%"
        center
    >
      <div>
        <el-date-picker
            style="width: 100%"
            v-model="dateYC"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
        </el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="subTime">确 定</el-button>
      </span>
    </el-dialog>

    <div class="code_img" v-if="twoCode">
      <img src="../../../static/close.png" alt="" @click="closeCode"
           style="width: 30px;height: 30px;position: absolute;right:0;top: 0;cursor: pointer">
      <img :src="twoCode" alt="" style="width: 430px;height: 430px">
    </div>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import {addExperienceAcount,queryListPage,deleteExperienceAcount,uodateUserRecord,getPageSunCodeInfoByAss} from "@/api/account";
import {Base64 as base64} from "js-base64";
export default {
  name: "accountList",
  data() {
    return {
      centerDialogVisible:false,
      centerDialogVisible1:false,
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      tableData: [],
      ruleForm:{
        title:'',
        desc:''
      },
      rules:{
        title: [{ required: true, message: "请输入账号名称", trigger: "blur" }],
      },
      srcList:[],
      dateYC:'',
      experienceId:'',
      userId:'',
      twoCode:''
    };
  },
  created() {
    this.queryPage()
  },
  components: {
    commonTable,
    customPopconfirm
  },
  methods: {
    /**@method 获取*/
    async queryPage(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        ...payload
      };
      try {
        this.loading = true;
        const result = await queryListPage(data);
        this.loading = false;
        const {total, list} = result.data.pageInfo;
        let a = []
        for (let i in list) {
          if (list[i].createTime) {
            list[i].createTime = this.renderTime(list[i].createTime);
          }
          if (list[i].nickeName) {
            list[i].nickeName = base64.decode(list[i].nickeName);
          }
          if (list[i].wxUrl) {
            a.push(list[i].wxUrl)
          }
        }
        this.srcList = a
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    handleSizeChange(val) {
      this.pageSize = val;
      let data = {
        orderFrom: this.orderFrom,
        ordersNo: this.ordersNo,
        goodsName: this.goodsName,
      };
      this.queryPage(data);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      let data = {
        orderFrom: this.orderFrom,
        ordersNo: this.ordersNo,
        goodsName: this.goodsName,
      };
      this.queryPage(data);
    },
    extendedTime(row){
      console.log(row)
      this.userId = row.userId
      this.experienceId = row.experienceId
      this.centerDialogVisible1 = true
    },
    async subTime(){
      if (this.dateYC == '') {
        this.$message.error('请选择时间')
        return
      }else{
        let ajax = {
          userId:this.userId,
          experienceAcountId:this.experienceId,
          endTime:this.dateYC
        }
        await uodateUserRecord(ajax);
        this.$message.success('延长成功')
        this.centerDialogVisible1 = false
        await this.queryPage();
      }
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await deleteExperienceAcount({ experienceId: val });
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        title:"",
        desc:"",
      }),
          this.centerDialogVisible = false;
    },
    determine(){
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              accountName:this.ruleForm.title,
              reamark:this.ruleForm.desc,
            };
            this.$message({
              message: "正在保存",
            });
            await addExperienceAcount(data)
            this.centerDialogVisible = false;
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async bindUser(row){
      let ajax = {
        associationId: localStorage.getItem("associationId"),
        experienceId:row.experienceId
      }
      try {
        const result = await getPageSunCodeInfoByAss(ajax);
        this.twoCode = result.data.url
      } catch (error) {
        console.log(error);
      }
    },
    closeCode(){
      this.twoCode = ''
    },
    experienceRecord(){
      this.$router.push("/recordTy");
    }
  },
};
</script>

<style lang="scss" scoped>
.code_img {
  position: fixed;
  top: 30%;
  z-index: 10000;
  left: 35%;
  background: #FFFFFF;
}

/deep/ .el-table__body {
  position: relative;
}
.code_img {
  padding: 20px;
}
</style>


