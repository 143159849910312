<template>
  <div>
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="会员信息" name="first">
        <list v-if="activeName == 'first'"  :jurisdiction="jurisdiction"/>
      </el-tab-pane>

      <el-tab-pane label="会长风采" name="second">
        <membersFc v-if="activeName == 'second'" :jurisdiction="jurisdiction"/>
      </el-tab-pane>

      <el-tab-pane label="优秀会员推荐" name="third">
        <hasrecommendedList v-if="activeName == 'third'" :jurisdiction="jurisdiction"/>
      </el-tab-pane>

      <el-tab-pane label="添加体验账号" name="fourth">
        <accountList v-if="activeName == 'fourth'" :jurisdiction="jurisdiction"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import list from "./memberComponents/list.vue";
import membersFc from "./memberComponents/membersFc.vue";
import hasrecommendedList from "./memberComponents/hasrecommendedList.vue";
import accountList from "./memberComponents/accountList.vue";
import { mapState } from 'vuex';

export default {
  name: "dynamicList",
  components: {
    list,
    membersFc,
    hasrecommendedList,
    accountList
  },

  data() {
    return {
      associationId: localStorage.getItem("associationId"),
      activeName: "first",
      jurisdiction:false,
    };
  },
  computed: {
    ...mapState(['routerArr']),

  },
  created(){
    if(this.$route.query.name){
      this.activeName = this.$route.query.name
    }
  },
  watch:{
    routerArr(val){
      this.jurisdiction = val.includes("040")
      console.log(this.jurisdiction,'this.jurisdiction')
    },
  },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>

<style lang="scss" scoped></style>



