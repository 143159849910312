<template>
  <div class="appointmentList-view">
    <div  style="margin-bottom:20px">
      <el-input
          clearable
          v-model="memberName"
          style="width: 200px"
          placeholder="请输入会员名称"
          maxlength="30"
      ></el-input>
      <el-input
          clearable
          v-model="enterpriseName"
          style="width: 200px;margin-left: 8px"
          placeholder="请输入企业名称"
          maxlength="30"
      ></el-input>
      <el-input
          clearable
          v-model="phone"
          style="width: 200px;margin-left: 8px"
          placeholder="联系电话"
          maxlength="30"
      ></el-input>
      <el-button
          class="v-primary ml10"
          type="primary"
          @click="queryAppointmentPage"
      >查询</el-button
      >
    </div>
    <commonTable
        :tableData="tableData"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :loading="loading"
        :total="total"
    >
      <template v-slot:table>
        <el-table-column type="selection" />
        <el-table-column prop="userName" align="center" label="姓名" show-overflow-tooltip/>
        <el-table-column prop="tel" align="center" label="联系电话" />
        <el-table-column prop="companyName" align="center" label="企业名称" />
        <el-table-column align="center" label="头像">
          <template slot-scope="scope">
            <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.headUrl"
                :preview-src-list="srcList">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" align="center" label="创建时间" />
        <el-table-column prop="state" align="center" label="操作">
          <template slot-scope="scope">
            <el-button plain  size="mini"  type="primary" @click="edit(scope.row.excellentId)">编辑资料</el-button>
            <customPopconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="del(scope.row.memberId)"
                icon="el-icon-info"
                icon-color="red"
                title="确认取消？">
              <el-button
                  type="danger"
                  size="mini"
                  class="ma ml10 mb10"
                  slot="reference"
                  plain
              >取消推荐</el-button
              >
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="编辑资料"
        :visible.sync="centerDialogVisible"
        @close="close"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <el-form
          ref="ruleForm" :model="ruleForm" :rules="rules" label-width="auto" class="view pa24" label-position="left"
      >
        <el-form-item prop="name" label="姓名">
          <el-input
              v-model="ruleForm.name"
              style="width: 100%"
              placeholder="请输入姓名"
              maxlength="30"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="头像" prop="headImg">
          <el-upload :action="uploadUrl"
                     list-type="picture-card"
                     :class="{ disabled: uploadDisabled }"
                     :on-remove="handleRemove"
                     :on-success="handleSuccess"
                     :file-list="fileList"
                     :limit="1">
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="所属行业" prop="industryId">
          <el-select
              v-model="ruleForm.industryId"
              style="width: 100%"
              @change="toChoose"
          >
            <el-option
                v-for="(item) in industryList"
                :key="item.industryId"
                :label="item.industryName"
                :value="item.industryId"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="address" label="公司地址">
          <el-input
              v-model="ruleForm.address"
              style="width: 100%"
              placeholder="请输入公司地址"
              show-word-limit
              maxlength="300"
          ></el-input>
        </el-form-item>
        <el-form-item prop="mobile" label="联系电话">
          <el-input
              v-model="ruleForm.mobile"
              style="width: 100%"
              placeholder="请输入联系电话"
              show-word-limit
              maxlength="11"
          ></el-input>
        </el-form-item>
        <el-form-item prop="memberDesc" label="会员介绍">
          <el-input
              v-model="ruleForm.memberDesc"
              style="width: 100%"
              placeholder="请输入会员介绍"
              type="textarea"
              :rows="4"
              show-word-limit
              maxlength="5000"
          ></el-input>
        </el-form-item>
        <el-form-item prop="" label="个人履历">
          <div style="border: 1px solid #d9d6d6;padding: 5px">
            <div  v-for="(item,index) in rModelList" style="padding: 10px 0;border-bottom: 1px solid #d9d6d6" :key="index">
              <div style="display: flex">
                <el-date-picker
                    value-format="yyyy-MM-dd"
                    v-model="item.startDate"
                    type="date"
                    :picker-options="pickerOptions"
                    placeholder="开始时间">
                </el-date-picker>
                <el-date-picker
                    value-format="yyyy-MM-dd"
                    v-model="item.endDate"
                    type="date"
                    :picker-options="pickerOptions"
                    placeholder="结束时间">
                </el-date-picker>
                <el-input
                    v-model="item.unit"
                    style="width: 50%"
                    placeholder="工作单位"
                ></el-input>
                <el-button
                    class="v-primary"
                    type="primary"
                    @click="delItem(index)"
                >删除</el-button
                >
              </div>
              <div style="margin-top: 10px">
                <el-input
                    v-model="item.content"
                    style="width: 100%"
                    :rows="4"
                    placeholder="工作内容"
                    type="textarea"
                    show-word-limit
                    maxlength="3000"
                ></el-input>
              </div>
            </div>
            <div style="margin-top: 10px">
              <el-button class="ma" type="primary" @click="addList"
              >+ 添加履历项</el-button
              >
            </div>
          </div>
        </el-form-item>
        <el-form-item label="选择模板" prop="templateId">
          <el-select
              v-model="ruleForm.templateId"
              style="width: 100%"
          >
            <el-option
                v-for="(item) in templateList"
                :key="item.templateId"
                :label="item.templateName"
                :value="item.templateId"
            />
          </el-select>
        </el-form-item>
<!--        <el-form-item label="发布内容" prop="details">-->
<!--          &lt;!&ndash; //富文本 &ndash;&gt;-->
<!--          <quill-editor-->
<!--              style="height: 100%"-->
<!--              v-model="ruleForm.details"-->
<!--              ref="myQuillEditor"-->
<!--              :options="editorOption"-->
<!--          />-->
<!--        </el-form-item>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import customPopconfirm from "@/components/common/customPopconfirm";
import commonTable from "@/components/common/commonTable";
import {selectExcellentPageList,cancelExcellentMember,queryExcellentById,upExcellent} from "@/api/members";
import {fetchIndustryList} from "@/api/organization";
export default {
  name: "hasrecommendedList",
  components: {
    commonTable,
    customPopconfirm
  },
  props:{
    jurisdiction:{
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() >= Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      templateList:[{templateId:1,templateName:'模板一'},{templateId:2,templateName:'模板二'}],
      fileList:[],
      memberName:'',
      enterpriseName:'',
      phone:'',
      centerDialogVisible:false,
      industryList:[],
      ruleForm:{
        name:'',
        industryId:'',
        industryName:'',
        address:'',
        mobile:'',
        memberDesc:'',
        headImg:'',
        templateId:''
      },
      rules:{

      },
      optionList: "", //全部分类
      tableData: [],
      total: 0, //总条数
      currentPage: 1, //当前页数
      queryDate: "",
      pageNum: 1,
      pageSize: 10,
      loading: false, //加载中
      dialogImageUrl: "",
      dialogVisible:false,
      uploadUrl: this.$store.state.uploadingUrl,
      rModelList:[],
      excellentId:'',
      userId:'',
      srcList:[],
      ossUrl: this.$store.state.ossUrl
    };
  },
  computed: {
    uploadDisabled() {
      return this.fileList.length !== 0;
    },
  },
  mounted() {
    this.getIndustryList()
    this.queryPage();
  },
  methods: {
    async queryPage(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        examineStatus:0,
        ifLaunch:0,
        ifBind:0,
        type:0,
        ...payload
      };
      try {
        this.loading = true;
        const result = await selectExcellentPageList(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        let a = []
        for (let i in list) {
          if (list[i].headUrl){
            a.push(list[i].headUrl)
          }
          list[i].createTime = this.renderTime(list[i].createTime)
        }
        this.srcList = a
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    //筛选查询符合条件数据
    queryAppointmentPage() {
      this.currentPage = 1;
      this.pageNum = 1;
      let data = {
        userName:this.memberName,
        companyName:this.enterpriseName,
        tel:this.phone
      }
      this.queryPage(data);
    },
    //当前页变更
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    close(){
      (this.ruleForm = {
        name:'',
        industryId:'',
        industryName:'',
        address:'',
        mobile:'',
        memberDesc:'',
        headImg:'',
        templateId:''
      }), this.centerDialogVisible = false;
      this.fileList = []
      this.rModelList = []
    },
    handleRemove(file, fileList) {
      this.fileList = []
      this.ruleForm.headImg = ''
    },
    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.headImg = this.ossUrl + file.response.data
      this.fileList = fileList;
    },
    addList(){
      console.log(this.rModelList)
      this.rModelList.push({
        content: "",
        endDate: "",
        startDate: "",
        unit: "",
        excellentId:this.excellentId,
      })
    },
    determine(){
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              excellentId:this.excellentId,
              associationId: localStorage.getItem("associationId"),
              userId:this.userId,
              userName: this.ruleForm.name,
              headUrl: this.ruleForm.headImg,
              industryId:this.ruleForm.industryId,
              industry:this.ruleForm.industryName,
              address:this.ruleForm.address,
              tel:this.ruleForm.mobile,
              introduce:this.ruleForm.memberDesc,
              details:JSON.stringify(this.rModelList),
              modelType:this.ruleForm.templateId
            };
            this.$message({
              message: "正在保存",
            });
            await upExcellent(data);
            this.centerDialogVisible = false;
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          return false;
        }
      });
    },
    edit(val) {
      let ajax = {
        excellentId:val,
      }
      queryExcellentById(ajax)
          .then((res) => {
            this.centerDialogVisible = true
            let details = res.data.excellent
            this.ruleForm.name = details.userName
            if (details.industryId) {
              this.ruleForm.industryId = details.industryId
              this.ruleForm.industryName = details.industry
            }
            if (details.tel) {
              this.ruleForm.mobile = details.tel
            }
            if (details.address) {
              this.ruleForm.address = details.address
            }
            if (details.headUrl) {
              this.fileList = [{url: details.headUrl, uid: 1}]
              this.ruleForm.headImg = details.headUrl
            }
            if (res.data.rModelList) {
              this.rModelList = res.data.rModelList
            }
            if (details.introduce) {
              this.ruleForm.memberDesc = details.introduce
            }
            if (details.modelType) {
              this.ruleForm.templateId = details.modelType
            }
            this.excellentId = details.excellentId
            this.userId = details.userId
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.message
            });
          });
    },
    toChoose() {
      for (let j in this.industryList) {
        if (this.ruleForm.industryId == this.industryList[j].industryId) {
          this.ruleForm.industryName = this.industryList[j].industryName
        }
      }
    },
    async del(val){
      try {
        await cancelExcellentMember({memberId:val});
        await this.queryPage();
      } catch (error) {
        console.log(error)
      }
    },
    //获取行业列表
    getIndustryList() {
      fetchIndustryList()
          .then(res => {
            this.industryList = res.data
          })
          .catch(err => {
            console.log(err)
          })
    },
    delItem(index){
      this.rModelList.splice(index,1)
    }
  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
</style>

